import { Link } from "react-router-dom";

const WritingPage = () => {
    const posts = [
        {
            title: "Lessons From a Year at BCG",
            link: "/writing/bcg",
        },
        // {
        //     title: null,
        //     link: null,
        // }
    ];

    return (
        <div className="page">
            <h1>Writing</h1>
            {posts.reverse().map((post, index) => (
                <div className="line-break">
                    <Link className="writing-link" to={post.link} target="_self">
                        {post.title}
                    </Link>
                </div>
            ))}
        </div>
    );
};

export {WritingPage};